const FolderIcon = () => {
  return (
    <svg className="w-[35px] h-auto" width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 2.38875H21.3333C22.6166 2.38875 23.6666 3.43875 23.6666 4.72209V16.3888C23.6666 17.6721 22.6166 18.7221 21.3333 18.7221H2.66659C1.38325 18.7221 0.333252 17.6721 0.333252 16.3888L0.344919 2.38875C0.344919 1.10542 1.38325 0.0554199 2.66659 0.0554199H8.69825C9.31658 0.0554199 9.91158 0.30042 10.3549 0.743753L11.9999 2.38875ZM2.66659 15.2221C2.66659 15.8638 3.19159 16.3888 3.83325 16.3888H20.1666C20.8083 16.3888 21.3333 15.8638 21.3333 15.2221V5.88875C21.3333 5.24709 20.8083 4.72209 20.1666 4.72209H3.83325C3.19159 4.72209 2.66659 5.24709 2.66659 5.88875V15.2221Z"
        fill="white"
        fillOpacity="0.8"
      />
    </svg>
  );
}

export default FolderIcon;
