import { AuthConsumer, AuthProviderData } from './AuthProvider';

export const LoginButton = () => {
  return (
    <AuthConsumer>
      {(value: AuthProviderData) =>
        value.user === null ? (
          <button
            className="text-white text-2xl w-fit h-fit py-2 px-6 rounded-md bg-dark-touch hover:bg-touch"
            onClick={value.login}
          >
            Login
          </button>
        ) : (
          <button
            className="text-white text-2xl w-fit h-fit py-2 px-6 rounded-md bg-dark-touch hover:bg-touch"
            onClick={value.logout}
          >
            Logout
          </button>
        )
      }
    </AuthConsumer>
  );
};
