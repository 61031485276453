const UploadPlus = () => {
  return (
    <svg width="70" height="70" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_7_1490)">
        <rect x="11" y="7" width="70" height="70" rx="35" fill="#6C5DD3" />
        <path
          d="M52 43H47V48C47 48.55 46.55 49 46 49C45.45 49 45 48.55 45 48V43H40C39.45 43 39 42.55 39 42C39 41.45 39.45 41 40 41H45V36C45 35.45 45.45 35 46 35C46.55 35 47 35.45 47 36V41H52C52.55 41 53 41.45 53 42C53 42.55 52.55 43 52 43Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_7_1490"
          x="0"
          y="0"
          width="92"
          height="92"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7_1490" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="3" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_7_1490" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_7_1490" result="effect2_dropShadow_7_1490" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_7_1490" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default UploadPlus;
