import { DefaultExtensionType, FileIcon, defaultStyles } from 'react-file-icon';

const FileDisplay = ({ file }: { file: File }) => {
  const fileExtension: DefaultExtensionType = file.name.split('.')[1] as DefaultExtensionType;

  return (
    <div className="flex flex-row items-center justify-center w-full h-fit mt-3">
      <div className="w-9 mr-5">
        <FileIcon extension={fileExtension} {...defaultStyles[fileExtension]} radius={1} />
      </div>
      <p className='text-white text-xl'>{file.name}</p>
    </div>
  );
};

export default FileDisplay;
