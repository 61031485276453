import { ADAPTER_EVENTS, CHAIN_NAMESPACES, CONNECTED_EVENT_DATA, CustomChainConfig, UserInfo } from '@web3auth/base';
import { Web3Auth } from '@web3auth/modal';
import { LOGIN_MODAL_EVENTS } from '@web3auth/ui';
import { SolanaWallet } from '@web3auth/solana-provider';
import React, { useCallback, useEffect, useState } from 'react';

const WEB3AUTH_CLIENT_ID = 'BPnBIdJeIcpR-HpWdb9raiVv7yJuVpD2JFHxWK6zBlNwJ3EvGIQ5sfQ20nOH6H3dw3uZVwUHXDs6JPi9Mh9E59I';

const solanaChainConfig: CustomChainConfig = {
  chainNamespace: CHAIN_NAMESPACES.SOLANA,
  rpcTarget: 'https://solana-mainnet.g.alchemy.com/v2/gmI6IRMjgqwfGBKU9lFNIABrKsBO88PT',
  blockExplorer: 'https://solscan.io/',
  chainId: '0x1',
  displayName: 'mainnet',
  ticker: 'SOL',
  tickerName: 'solana',
};

export interface AuthProviderData {
  web3auth: Web3Auth | null;
  provider: CONNECTED_EVENT_DATA | null;
  user: Partial<UserInfo> | null;
  solanaWallet: SolanaWallet | null;
  onSuccessfulLogin: (data: CONNECTED_EVENT_DATA, user: any) => void;
  login: () => void;
  logout: () => void;
}

export const AuthProviderContext = React.createContext<AuthProviderData>({
  web3auth: null,
  provider: null,
  user: null,
  solanaWallet: null,
  onSuccessfulLogin: (data: any) => {},
  login: () => {},
  logout: () => {},
});

const web3auth = new Web3Auth({
  chainConfig: solanaChainConfig,
  clientId: WEB3AUTH_CLIENT_ID,
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [provider, setProvider] = useState<CONNECTED_EVENT_DATA | null>(null);
  const [user, setUser] = useState<Partial<UserInfo> | null>(null);
  const [solanaWallet, setSolanaWallet] = useState<SolanaWallet | null>(null);

  const onSuccessfulLogin = useCallback((data: CONNECTED_EVENT_DATA, user: Partial<UserInfo>) => {
    console.log('onSuccessfulLogin', data, user);
    setProvider(data);
    setUser(user);
    setSolanaWallet(new SolanaWallet(web3auth.provider!));
  }, []);

  const login = useCallback(() => {
    web3auth
      .connect()
      .then((data) => {
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const logout = useCallback(() => {
    web3auth
      .logout()
      .then(() => {
        // login on logout
      })
      .catch((err) => {
        console.log('logout', err);
      });
  }, []);

  const subscribeAuthEvents = useCallback(
    (web3auth: Web3Auth) => {
      web3auth.on(ADAPTER_EVENTS.CONNECTED, (data: CONNECTED_EVENT_DATA) => {
        web3auth.getUserInfo().then((user) => {
          onSuccessfulLogin(data, user);
        });
      });

      web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
        console.log('connecting');
      });

      web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
        console.log('disconnected');
        setUser(null);
        setProvider(null);
        setSolanaWallet(null);
      });

      web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
        console.log('some error or user have cancelled login request', error);
      });

      web3auth.on(LOGIN_MODAL_EVENTS.MODAL_VISIBILITY, (isVisible) => {
        console.log('modal visibility', isVisible);
      });
    },
    [onSuccessfulLogin]
  );

  useEffect(() => {
    subscribeAuthEvents(web3auth);

    web3auth.initModal().catch((err) => {
      alert('error' + err);
    });
  }, []);

  const ctx: AuthProviderData = {
    web3auth,
    provider,
    user,
    solanaWallet,
    onSuccessfulLogin,
    login,
    logout,
  };
  return <AuthProviderContext.Provider value={ctx}>{children}</AuthProviderContext.Provider>;
};

export const AuthConsumer = AuthProviderContext.Consumer;
