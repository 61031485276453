import Header from './components/Header';
import MainPage from './pages/MainPage';
import ErrorMessage from './components/UI/ErrorMessage';
import ErrorProvider from './store/ErrorProvider';
import SolanaWalletProvider from './store/SolanaWalletProvider';
import { Route, Routes } from 'react-router-dom';
import SentFilesPage from './pages/SentFilesPage';
import NotificationsPage from './pages/NotificationsPage';
import SettingsPage from './pages/SettingsPage';
import HelpAndFeedbackPage from './pages/HelpAndFeedbackPage';
import { AuthProvider } from './store/AuthProvider';

const App = () => {
  return (
    <SolanaWalletProvider>
      <ErrorProvider>
        <AuthProvider>
          <Header />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/sent-files" element={<SentFilesPage />} />
            <Route path="/notifications" element={<NotificationsPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/help-feedback" element={<HelpAndFeedbackPage />} />
          </Routes>
          <ErrorMessage />
        </AuthProvider>
      </ErrorProvider>
    </SolanaWalletProvider>
  );
};

export default App;
