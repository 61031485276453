import { useWallet } from '@solana/wallet-adapter-react';
import { useContext, useState } from 'react';
import ErrorContext from '../../store/error-context';
import CheckedCheckbox from '../UI/icons/CheckedCheckbox';
import SolanaLogo from '../UI/icons/SolanaLogo';
import UncheckedCheckbox from '../UI/icons/UncheckedCheckbox';
import UploadPlus from '../UI/icons/UploadPlus';
import { AuthProviderContext } from '../../store/AuthProvider';

const GatedUpload = (props: any) => {
  const wallet = useWallet();
  const web3auth = useContext(AuthProviderContext);
  const [checked, setChecked] = useState({ 1: false, 2: false });
  const errorCtx = useContext(ErrorContext);

  const handleShowErrorMessage = () => {
    errorCtx.setTitle('Connect wallet');
    errorCtx.setMessage('Please connect your wallet before proceeding.');
    errorCtx.setShown();
  };

  return (
    <div className="h-[80%] w-full block overflow-x-hidden overflow-y-auto text-white">
      <div className="w-full h-[30%] flex items-center justify-center cursor-pointer" onClick={props.handleFileUpload}>
        <div className="flex w-fit h-fit">
          <UploadPlus />
          <div className="flex flex-col items-center justify-around">
            <div className="text-center">
              <h1 className="text-2xl self-center ml-1 text-white">Upload files</h1>
              <p className=" underline underline-offset-1 text-touch">or select a folder</p>
            </div>
          </div>
        </div>
      </div>
      <form id="gatedform" className="w-full h-fit p-5 block font-light" onSubmit={props.handleFormSubmit}>
        <label>Logged in as:</label>
        <input
          className="block border bg-upload-bg border-outline rounded-md w-full p-2 my-2"
          type="email"
          value={web3auth.user !== null ? web3auth.user.name : 'Please login'}
          required={true}
          readOnly={true}
        />
        <label>Transfer wallets</label>
        <input
          className="block border bg-upload-bg border-outline rounded-md w-full p-2 my-2"
          type="email"
          placeholder="Send files to..."
          required={true}
          readOnly={web3auth.user === null}
          onClick={web3auth.user === null ? handleShowErrorMessage : () => {}}
        />
        <label className="text-xs text-gray-300 block my-2">Separate multiple wallets with commas</label>
        <label className="block">Must hold</label>
        <label
          className="block mt-1"
          onClick={web3auth.user === null ? handleShowErrorMessage : () => setChecked({ ...checked, 1: !checked[1] })}
        >
          {checked[1] ? <CheckedCheckbox /> : <UncheckedCheckbox />}
          Survivors NFT
        </label>
        <label
          className="block my-2"
          onClick={web3auth.user === null ? handleShowErrorMessage : () => setChecked({ ...checked, 2: !checked[2] })}
        >
          {checked[2] ? <CheckedCheckbox /> : <UncheckedCheckbox />}
          $STAX
        </label>
        <label className="block mt-2">Must pay</label>
        <div
          className="border border-outline rounded-md w-full my-2 flex items-center"
          onClick={web3auth.user === null ? handleShowErrorMessage : () => {}}
        >
          <input
            className="w-[75%] h-full bg-upload-bg p-2 rounded-md"
            type="number"
            placeholder="Enter Amount"
            readOnly={web3auth.user === null}
          />
          <div className="h-full w-[25%] flex justify-around items-center">
            <SolanaLogo />
            <p className="ml-1 font-semibold">SOL</p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GatedUpload;
