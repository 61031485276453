const HelpIcon = () => {
  return (
    <svg
      className="w-[35px] h-auto"
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_77_8812)">
        <path
          d="M13.9999 26.0554C7.55642 26.0554 2.33325 20.8322 2.33325 14.3887C2.33325 7.94521 7.55642 2.72205 13.9999 2.72205C20.4434 2.72205 25.6666 7.94521 25.6666 14.3887C25.6666 20.8322 20.4434 26.0554 13.9999 26.0554ZM13.9999 23.722C16.4753 23.722 18.8492 22.7387 20.5996 20.9884C22.3499 19.238 23.3333 16.8641 23.3333 14.3887C23.3333 11.9134 22.3499 9.53939 20.5996 7.78905C18.8492 6.03871 16.4753 5.05538 13.9999 5.05538C11.5246 5.05538 9.15059 6.03871 7.40026 7.78905C5.64992 9.53939 4.66659 11.9134 4.66659 14.3887C4.66659 16.8641 5.64992 19.238 7.40026 20.9884C9.15059 22.7387 11.5246 23.722 13.9999 23.722ZM12.8333 17.8887H15.1666V20.222H12.8333V17.8887ZM15.1666 15.9695V16.722H12.8333V14.972C12.8333 14.6626 12.9562 14.3659 13.175 14.1471C13.3938 13.9283 13.6905 13.8054 13.9999 13.8054C14.3313 13.8054 14.6559 13.7112 14.936 13.5339C15.216 13.3566 15.4399 13.1035 15.5817 12.8039C15.7234 12.5043 15.7772 12.1706 15.7367 11.8417C15.6962 11.5128 15.5632 11.2021 15.3531 10.9458C15.1429 10.6895 14.8643 10.4981 14.5497 10.394C14.235 10.2899 13.8973 10.2772 13.5757 10.3575C13.2542 10.4378 12.9621 10.6078 12.7333 10.8477C12.5046 11.0875 12.3487 11.3874 12.2838 11.7124L9.99475 11.2539C10.1367 10.5446 10.4643 9.88581 10.9443 9.34473C11.4243 8.80365 12.0394 8.39972 12.7266 8.17425C13.4139 7.94878 14.1487 7.90985 14.8559 8.06143C15.5631 8.21301 16.2174 8.54968 16.7519 9.03701C17.2863 9.52434 17.6818 10.1449 17.8979 10.8351C18.1139 11.5254 18.1428 12.2606 17.9816 12.9657C17.8204 13.6708 17.4748 14.3205 16.9802 14.8482C16.4856 15.376 15.8597 15.7629 15.1666 15.9695Z"
          fill="white"
          fill-opacity="0.8"
        />
      </g>
      <defs>
        <clipPath id="clip0_77_8812">
          <rect width="28" height="28" fill="white" transform="translate(0 0.388672)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HelpIcon;
