const CheckedCheckbox = () => {
  return (
    <svg
      className="inline-block mr-2"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="4" fill="#6C5DD3" />
      <path
        d="M7.32922 13.2292L4.43755 10.3375C4.28186 10.1815 4.07048 10.0938 3.85005 10.0938C3.62962 10.0938 3.41824 10.1815 3.26255 10.3375C2.93755 10.6625 2.93755 11.1875 3.26255 11.5125L6.74588 14.9958C7.07088 15.3208 7.59588 15.3208 7.92088 14.9958L16.7375 6.17917C17.0625 5.85417 17.0625 5.32917 16.7375 5.00418C16.5819 4.84813 16.3705 4.76044 16.15 4.76044C15.9296 4.76044 15.7182 4.84813 15.5625 5.00418L7.32922 13.2292Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckedCheckbox;
