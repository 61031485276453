import { useContext } from 'react';
import ErrorContext from '../../store/error-context';

const ErrorMessage = () => {
  const errorCtx = useContext(ErrorContext);

  if (errorCtx.shown) {
    return (
      <div className="w-[100vw] h-[100vh] right-0 bottom-0 fixed -z-10">
        <div className="w-96 h-28 right-0 bottom-0 m-5 absolute rounded-lg flex bg-upload-bg p-5 items-center animate-slide-left">
          <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16 2H8L2 8V16L8 22H16L22 16V8L16 2Z"
              stroke="#db233c"
              stroke-width="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M12 8V12" stroke="#db233c" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 16.0195V16" stroke="#db233c" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <div className="block ml-5 text-gray-300">
            <p className="text-xl font-semibold">{errorCtx.title}</p>
            <p className="text-md">{errorCtx.message}</p>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default ErrorMessage;
