import { useContext, useRef } from 'react';
import UploadPlus from '../UI/icons/UploadPlus';
import FileDisplay from './FileDisplay';
import { AuthProviderContext } from '../../store/AuthProvider';
import ErrorContext from '../../store/error-context';

const StandardUpload = (props: any) => {
  // const [uploadedFiles, setUploadedFiles] = useState<File[]>(props.selectedFiles);
  const fileInput: any = useRef(null);
  const web3auth = useContext(AuthProviderContext);
  const errorCtx = useContext(ErrorContext);

  const handleUpload = async (event: any) => {
    console.log("handleUpload")
    await props.handleFileUpload(event);
  };
  return (
    <div
      className={`h-[80%] w-full flex items-center cursor-pointer ${
        props.selectedFiles.length > 0 ? 'flex-col justify-between overflow-y-auto' : 'justify-center'
      }`}
    >
      {props.selectedFiles.length > 0 ? (
        <>
          <input type="file" id="fileInput" ref={fileInput} onChange={handleUpload} className="hidden" multiple />
          <div
            className="flex w-fit h-fit mt-5 mb-5"
            onClick={() => {
              fileInput!.current!.click();
              // setUploading(true);
            }}
          >
            <UploadPlus />
            <div className="flex flex-col items-center justify-around">
              <div className="text-center">
                <h1 className="text-2xl self-center ml-1 text-white">Upload more files</h1>
                {/* <p className="underline underline-offset-1 text-touch">or select a folder</p> */}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center w-full h-fit mb-5">
            {props.selectedFiles.map((file: File) => (
              <FileDisplay key={file.name} file={file} />
            ))}
          </div>
        </>
      ) : (
        <>
          <input type="file" id="fileInput" ref={fileInput} onChange={handleUpload} className="hidden" multiple />
          <div
            className="flex w-fit h-fit"
            onClick={() => {
              if (web3auth.user === null) {
                errorCtx.setTitle('Login Required');
                errorCtx.setMessage('Please log in to upload files');
                errorCtx.setShown();
                return;
              }
              fileInput!.current!.click();
              // setUploading(true);
            }}
          >
            <UploadPlus />
            <div className="flex flex-col items-center justify-around">
              <div className="text-center">
                <h1 className="text-2xl self-center ml-1 text-white">Upload files</h1>
                <p className="underline underline-offset-1 text-touch">or select a folder</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StandardUpload;
