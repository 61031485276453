const HamburgerItem = (props: { name: string; icon: any; onClick: () => void; hideHamburger: () => void }) => {
  return (
    <div
      className="flex justify-left w-full h-fit items-center mt-5 cursor-pointer"
      onClick={() => {
        props.hideHamburger();
        props.onClick();
      }}
    >
      {props.icon}
      <p className="text-xl text-hamburger-text font-[500] ml-7">{props.name}</p>
    </div>
  );
};

export default HamburgerItem;
