import { useState } from 'react';
import UploadPlus from '../UI/icons/UploadPlus';

const MobileUpload = () => {
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // const handleFileUpload = () => { // todo
  //   console.log('Uploading file...');

  //   setIsFileUploaded(true);
  // };

  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file');
      return;
    }

    // Process the file upload here (e.g., send it to the server)
    console.log('File uploaded:', selectedFile);
  };

  return (
    <>
      <input type="file" onChange={handleFileChange} />
      <div className="w-full h-fit flex flex-col items-center" onClick={handleFileUpload}>
        <UploadPlus />

        <p className="text-2xl mb-1">Upload Files</p>
        <p className="underline underline-offset-1 text-touch text-lg">Add up to 2GB</p>
      </div>
    </>
  );
};

export default MobileUpload;
