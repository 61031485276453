const NotificationsIcon = () => {
  return (
    <svg
      className="w-[35px] h-auto"
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.5053 20.2688L21.0003 18.7638V12.9305C21.0003 9.34879 19.087 6.35046 15.7503 5.55713V4.76379C15.7503 3.79546 14.9686 3.01379 14.0003 3.01379C13.032 3.01379 12.2503 3.79546 12.2503 4.76379V5.55713C8.90197 6.35046 7.0003 9.33713 7.0003 12.9305V18.7638L5.4953 20.2688C4.7603 21.0038 5.27364 22.2638 6.31197 22.2638H21.677C22.727 22.2638 23.2403 21.0038 22.5053 20.2688ZM9.33364 19.9305V12.9305C9.33364 10.0371 11.0953 7.68046 14.0003 7.68046C16.9053 7.68046 18.667 10.0371 18.667 12.9305V19.9305H9.33364ZM16.3336 23.4305C16.3336 24.7138 15.2836 25.7638 14.0003 25.7638C12.7053 25.7638 11.667 24.7138 11.667 23.4305H16.3336Z"
        fill="white"
        fill-opacity="0.8"
      />
    </svg>
  );
};

export default NotificationsIcon;
