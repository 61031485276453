import HamburgerItem from "./HamburgerItem";
import FolderIcon from "./icons/FolderIcon";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "./icons/NotificationsIcon";
import SettingsIcon from "./icons/SettingsIcon";
import HelpIcon from "./icons/HelpIcon";

const HamburgerMobile = (props: { hideHamburger: () => void; hidingHamburger: boolean }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`z-20 fixed top-0 bottom-0 left-0 w-[70%] h-full ${
        !props.hidingHamburger ? 'animate-slide-right' : 'animate-hide-hamburger'
      } overflow-hidden text-white bg-hamburger-bg`}
    >
      <div className="w-full h-fit my-5 flex flex-col items-center justify-center cursor-pointer" onClick={() => { props.hideHamburger(); navigate("/")}}>
        <img className="w-auto h-16" src="/images/synx-lock-icon.png" alt="Synx lock icon" />
        <img className="h-20 -mt-3" src="/images/synx-logo.png" alt="synx logo" />
      </div>
      <Separator />
      <div className="w-full h-fit flex flex-col items-center justify-around pl-7 mt-2 mb-7">
        <HamburgerItem
          name="Sent Files"
          icon={<FolderIcon />}
          hideHamburger={props.hideHamburger}
          onClick={() => navigate('/sent-files')}
        />
        <HamburgerItem
          name="Notifications"
          icon={<NotificationsIcon />}
          hideHamburger={props.hideHamburger}
          onClick={() => navigate('/notifications')}
        />
      </div>
      <Separator />
      <div className="w-full h-fit flex flex-col items-center justify-around pl-7 mt-2 mb-7">
        <HamburgerItem
          name="Settings"
          icon={<SettingsIcon />}
          hideHamburger={props.hideHamburger}
          onClick={() => navigate('/settings')}
        />
        <HamburgerItem
          name="Help &amp; Feedback"
          icon={<HelpIcon />}
          hideHamburger={props.hideHamburger}
          onClick={() => navigate('/help-feedback')}
        />
      </div>
    </div>
  );
};



const Separator = () => {
  return (<div className="w-full h-[1px] bg-white bg-opacity-50" />)
}

export default HamburgerMobile;
