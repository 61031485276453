const SolanaLogo = () => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.6729 5.26815H15.0535C15.2337 5.26815 15.4047 5.3363 15.529 5.45778L17.804 7.64148C18.2236 8.04445 17.9252 8.73185 17.3285 8.73185H2.94793C2.76767 8.73185 2.59673 8.6637 2.47241 8.54222L0.197382 6.35852C-0.225301 5.95556 0.0761714 5.26815 0.6729 5.26815ZM0.197382 2.37333L2.47241 0.189629C2.59984 0.068148 2.77078 0 2.94793 0H17.3254C17.9221 0 18.2236 0.687407 17.8009 1.09037L15.529 3.27407C15.4015 3.39556 15.2306 3.4637 15.0535 3.4637H0.6729C0.0761714 3.4637 -0.225301 2.7763 0.197382 2.37333ZM17.8009 11.6267L15.5259 13.8104C15.3984 13.9319 15.2275 14 15.0503 14H0.6729C0.0761714 14 -0.225301 13.3126 0.197382 12.9096L2.47241 10.7259C2.59984 10.6044 2.77078 10.5363 2.94793 10.5363H17.3254C17.9221 10.5363 18.2236 11.2237 17.8009 11.6267Z"
        fill="url(#paint0_linear_38_8765)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_38_8765"
          x1="1.02472"
          y1="14.6024"
          x2="16.2121"
          y2="-1.32828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CB4EE8" />
          <stop offset="1" stopColor="#10F4B1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SolanaLogo;
