import MobileUpload from '../components/Upload/MobileUpload';
import UploadForm from '../components/Upload/UploadForm';

const MainPage = () => {
  return (
    <>
      <div className="xl:flex hidden w-full h-[85vh]">
        <div className="h-full w-[35%] p-10 flex justify-center">
          <UploadForm />
        </div>
        <div className="h-[90%] w-[65%] p-10 flex flex-col justify-between">
          <div className="text-white w-full h-fit flex justify-between">
            <div className="w-[60%]">
              <h1 className="text-5xl mb-5 font-bold">
                Encrypt and send files in <span className="text-touch">total privacy</span>
              </h1>
              <p className="text-2xl mt-10 leading-relaxed">
                <span className="text-touch">Synx Send</span> allows you to share files fast without worrying about the
                security of your documents, we store and encrypt your data on the Solana blockchain.
              </p>
            </div>
            <img className="w-fit h-fit" src="/images/wallet.png" alt="wallet" />
          </div>
          <div className="flex justify-left w-[90%] h-fit">
            <img className="w-fit h-fit mr-[10vw]" src="/images/workspace.png" alt="workspace" />
            <img className="w-fit h-fit" src="/images/folders.png" alt="folders" />
          </div>
        </div>
      </div>
      <div className="xl:hidden flex flex-col text-white items-center w-full h-[85vh]">
        <div className="w-[70%] h-fit text-center">
          <h1 className="text-4xl md:text-5xl font-bold mt-[10vh] mb-[15vh]">
            Encrypt and send files in <span className="text-touch">total privacy</span>
          </h1>
        </div>
        <MobileUpload />
      </div>
    </>
  );
};

export default MainPage;
