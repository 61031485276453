import { useContext, useState } from 'react';
import GatedUpload from './GatedUpload';
import StandardUpload from './StandardUpload';
import CheckedCheckbox from '../UI/icons/CheckedCheckbox';
import UncheckedCheckbox from '../UI/icons/UncheckedCheckbox';
import { AuthProviderContext } from '../../store/AuthProvider';
import { ShdwDrive } from '@shadow-drive/sdk';
import { Connection, Keypair } from '@solana/web3.js';
import ErrorContext from '../../store/error-context';
import bs58 from 'bs58';
import { getED25519Key } from '@toruslabs/openlogin-ed25519';

const UploadForm = () => {
  const [uploadMethod, setUploadMethod] = useState('standard'); // standard / gated
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const web3auth = useContext(AuthProviderContext);
  const errorCtx = useContext(ErrorContext);

  const handleFileUpload = (event: any) => {
    const localSelectedFiles: FileList = event.target.files;
    const localFilesArray = Array.from(localSelectedFiles);
    let toReturn: File[] = [];
    setSelectedFiles((prev: File[]) => {
      if (prev.length === 0) {
        toReturn = [...localFilesArray];
      } else {
        toReturn = [...prev, ...localFilesArray];
      }
      return toReturn;
    });
  };

  const userInfo = async () => {
    const info = await web3auth.web3auth
    console.log('userInfo', info);
  };

  const handleGatedFormSubmit = (event: any) => {
    event.preventDefault();

    const sendTo = event.target[0].value;
    const yourEmail = event.target[1].value;
    const transferTitle = event.target[2].value;
    const transferMessage = event.target[3].value;

    console.log(sendTo, yourEmail, transferTitle, transferMessage);
  };

  // upload files from selectedFiles to shadow storage using their api
  const handleUploadToShadow = async () => {
    const accs = await web3auth.solanaWallet!.requestAccounts()
    console.log('accs', accs)

    const connectionConfig: any = await web3auth.solanaWallet!.request({
      method: 'solana_provider_config',
      params: [],
    });
    const privateKeyMight = await web3auth.web3auth!.provider!.request({
      method: 'solanaPrivateKey',
    });

    if (typeof privateKeyMight !== 'string') {
      errorCtx.setTitle('Invalid Private Key');
      errorCtx.setMessage('Invalid Wallet Private Key');
      errorCtx.setShown();
      return;
    }

    const privateKey: string = privateKeyMight as string;
    const { pk, sk } = getED25519Key(privateKey);

    const keypair1 = new Keypair({publicKey: pk, secretKey: sk});
    console.log("finall pk1", keypair1.publicKey.toString())

    const connection = new Connection(connectionConfig.rpcTarget);
    const drive = await new ShdwDrive(connection, keypair1).init();
    const accts = await drive.getStorageAccounts("v2");
    console.log(accts);
  };

  return (
    <div className="bg-upload-bg rounded-2xl border border-outline h-[95%] w-[80%] overflow-hidden">
      {uploadMethod === 'standard' ? (
        <StandardUpload handleFileUpload={handleFileUpload} selectedFiles={selectedFiles} />
      ) : (
        <GatedUpload
          handleFileUpload={handleFileUpload}
          handleFormSubmit={handleGatedFormSubmit}
          selectedFiles={selectedFiles}
        />
      )}
      <div className="h-[20%] w-full block">
        <div className="mt-4 mb-2 flex justify-center w-full h-[30%]">
          <div className="flex justify-around rounded-md w-full h-[80%] p-0.5 cursor-pointer text-white">
            <div
              className="w-[49%] h-full rounded-md flex justify-center items-center"
              onClick={() => {
                setUploadMethod('standard');
                // setIsFileUploaded(false);
              }}
            >
              {uploadMethod === 'standard' ? <CheckedCheckbox /> : <UncheckedCheckbox />}
              <p>Standard Link</p>
            </div>
            <div
              className="w-[49%] h-full rounded-md flex justify-center items-center"
              onClick={() => {
                setUploadMethod('gated');
                // setIsFileUploaded(false);
              }}
            >
              {uploadMethod === 'gated' ? <CheckedCheckbox /> : <UncheckedCheckbox />}
              <p>Gated Link</p>
            </div>
          </div>
        </div>
        <div className="mt-2 mb-4 flex w-full p-3 h-[50%] justify-center">
          <button
            className={`h-full w-full rounded-lg text-white font-semibold ${
              selectedFiles.length !== 0 && web3auth.user !== null
                ? 'bg-dark-touch cursor-pointer'
                : 'bg-gray-300 cursor-default'
            }`}
            form={uploadMethod === 'gated' ? 'gatedform' : ''}
            onClick={handleUploadToShadow}
          >
            {uploadMethod === 'standard' ? 'Get a link' : 'Send files'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadForm;
