import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import Hamburger from 'hamburger-react';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import HamburgerMobile from './HamburgerMenu/HamburgerMobile';
import InfoIcon from './UI/icons/InfoIcon';
import { LoginButton } from '../store/LoginButton';

const Header = () => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [hideHamburger, setHideHamburger] = useState(false);
  const navigate = useNavigate();

  const handleInfoClick = () => {};

  const handleCloseHamburger = () => {
    setHideHamburger(true);
    setTimeout(() => {
      setIsHamburgerOpen(false);
      setHideHamburger(false);
    }, 300);
  };

  return (
    <>
      <div className="xl:flex hidden w-full h-[15vh] justify-between py-6 px-14">
        <img
          className="h-16 cursor-pointer"
          src="/images/synx-logo.png"
          alt="synx logo"
          onClick={() => navigate('/')}
        />
        {/* <WalletMultiButton /> */}
        <LoginButton />
      </div>
      <div className="xl:hidden flex w-full h-[15vh] justify-between py-6 px-5 bg-mobile-header text-touch items-center">
        <Hamburger toggled={isHamburgerOpen} toggle={setIsHamburgerOpen} />
        <img
          className="cursor-pointer h-16 md:h-20"
          src="/images/synx-lock-icon.png"
          alt="synx lock icon"
          onClick={() => navigate('/')}
        />
        <InfoIcon onClick={handleInfoClick} />
      </div>
      {isHamburgerOpen &&
        ReactDOM.createPortal(
          <>
            <div
              className="w-full h-full z-10 bg-black bg-opacity-30 fixed overflow-hidden top-0 left-0"
              onClick={handleCloseHamburger}
            />
            <HamburgerMobile hideHamburger={handleCloseHamburger} hidingHamburger={hideHamburger} />
          </>,
          document.getElementById('overlay')!
        )}
    </>
  );
};

export default Header;
