import React from 'react';

const ErrorContext = React.createContext({
  title: 'Title',
  setTitle: (title: string) => {},
  message: 'Message',
  setMessage: (message: string) => {},
  shown: false,
  setShown: () => {},
});

export default ErrorContext;
